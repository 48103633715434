import styled from 'styled-components'
import { calculateResponsiveSize, calculateTabletResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'
import { ButtonStyles } from 'components/header/index.styled'

export const ContactWrapper = styled.section`
  padding: ${calculateResponsiveSize(80)} ${calculateResponsiveSize(40)};
  font-size: ${calculateResponsiveSize(12)};
  color: #000000;
  background: #fbf4ed;
  position: relative;
  z-index: 3;
  ${mediaMax('desktopSm')} {
    padding: ${calculateTabletResponsiveSize(80)} ${calculateTabletResponsiveSize(40)};
    font-size: ${calculateTabletResponsiveSize(12)};
  }
  ${mediaMax('mobile')} {
    padding: 80px 20px;
    font-size: 12px;
  }
`

export const ContactInn = styled.div`
  transition: opacity 1s;
  opacity: 1;
  position: relative;
  z-index: 1;
  &.show{
    opacity: 1;
  }
  &.hide{
    opacity: 0;
  }
`

export const Title = styled.h3`
  margin: 0 0 ${calculateResponsiveSize(34)} 0;
  text-align: center;
  font-size: ${calculateResponsiveSize(15)};
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: ${calculateResponsiveSize(0.6)};
  text-transform: uppercase;
  overflow: hidden;
  .hide & {
    opacity: 0;
    visibility: hidden;
  }
  ${mediaMax('desktopSm')} {
    margin: 0 0 ${calculateTabletResponsiveSize(34)} 0;
    font-size: ${calculateTabletResponsiveSize(15)};
    letter-spacing: ${calculateTabletResponsiveSize(0.6)};
  }
  ${mediaMax('mobile')} {
    margin: 0 0 34px 0;
    font-size: 15px;
    letter-spacing: 0.6px;
  }
`

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${calculateResponsiveSize(22)};
  width: 100%;
  max-width: ${calculateResponsiveSize(580)};
  margin: 0 auto;
  .hide & {
    opacity: 0;
    visibility: hidden;
  }
  ${mediaMax('desktopSm')} {
    gap: ${calculateTabletResponsiveSize(22)};
    max-width: ${calculateTabletResponsiveSize(580)};
  }
  ${mediaMax('mobile')} {
    gap: 22px;
    max-width: 580px;
  }
`

export const Row = styled.div`
  display: flex;
  gap: ${calculateResponsiveSize(40)};
  ${mediaMax('desktopSm')} {
    gap: ${calculateTabletResponsiveSize(40)};
  }
  ${mediaMax('mobile')} {
    gap: 20px;
  }
`

export const Radios = styled.div`
  width: 100%;
`

export const Text = styled.div`
  margin: 0 0 ${calculateResponsiveSize(10)} 0;
  ${mediaMax('desktopSm')} {
    margin: 0 0 ${calculateTabletResponsiveSize(10)} 0;
  }
  ${mediaMax('mobile')} {
    margin: 0 0 10px 0;
  }
`

export const Info = styled.div`
  width: 100%;
  text-align: right;
  font-style: italic;
  &.error {
    color: #EF3E42;
  }
`

export const ButtonWrapper = styled.div`
  padding-top: ${calculateResponsiveSize(18)};
  ${mediaMax('desktopSm')} {
    padding-top: ${calculateTabletResponsiveSize(18)};
  }
  ${mediaMax('mobile')} {
    padding-top: 20px;
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${calculateResponsiveSize(270)};
  height: ${calculateResponsiveSize(25)};
  margin: 0 auto;
  font-size: ${calculateResponsiveSize(10)};
  color: #ffffff;
  font-weight: 600;
  letter-spacing: ${calculateResponsiveSize(1)};
  text-transform: uppercase;
  border-radius: 6px;
  border: 0;
  background: #000000;
  transition: all 0.25s;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.50);
  }
  &:disabled {
    background: rgba(0, 0, 0, 0.30);
    cursor: not-allowed;
  }
  ${mediaMax('desktopSm')} {
    width: ${calculateTabletResponsiveSize(270)};
    height: ${calculateTabletResponsiveSize(25)};
    font-size: ${calculateTabletResponsiveSize(10)};
    letter-spacing: ${calculateTabletResponsiveSize(1)};
  }
  ${mediaMax('mobile')} {
    width: 168px;
    height: 20px;
    font-size: 9px;
    letter-spacing: 0.9px;
  }
`

export const ThanksMessage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${calculateResponsiveSize(80)} ${calculateResponsiveSize(40)};
  text-align: center;
  font-size: ${calculateResponsiveSize(14)};
  line-height: 1.6;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  &.show{
    animation: 2s ease 0s normal forwards 1 fadeIn;
    @keyframes fadeIn {
      0% {
        opacity: 0;
        visibility: hidden;
      }
      100% {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  ${mediaMax('desktopSm')} {
    padding: ${calculateTabletResponsiveSize(80)} ${calculateTabletResponsiveSize(40)};
    font-size: ${calculateTabletResponsiveSize(14)};
  }
  ${mediaMax('mobile')} {
    padding: 80px 20px;
    font-size: 14px;
  }
`

export const ThanksMessageTitle = styled.h2`
  margin: 0 0 ${calculateResponsiveSize(22)} 0;
  font-family: "Didot";
  font-size: ${calculateResponsiveSize(40)};
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: ${calculateResponsiveSize(-0.8)};
  overflow: hidden;
  ${mediaMax('desktopSm')} {
    margin: 0 0 ${calculateTabletResponsiveSize(22)} 0;
    font-size: ${calculateTabletResponsiveSize(40)};
    letter-spacing: ${calculateTabletResponsiveSize(-0.8)};
  }
  ${mediaMax('mobile')} {
    margin: 0 0 22px 0;
    font-size: 40px;
    letter-spacing: -1.2px;
  }
`

export const ThanksMessageDescription = styled.div`
  max-width: ${calculateResponsiveSize(520)};
  margin: 0 auto ${calculateResponsiveSize(34)} auto;
  ${mediaMax('desktopSm')} {
    max-width: ${calculateTabletResponsiveSize(520)};
    margin: 0 auto ${calculateTabletResponsiveSize(34)} auto;
  }
  ${mediaMax('mobile')} {
    max-width: 520px;
    margin: 0 auto 34px auto;
  }
`

export const ButtonBack = styled.div`
  ${ButtonStyles}
  color: #000000;
  font-weight: normal;
  border-color: #000000;
  &:hover {
    color: #ffffff;
    background: #000000;
  }
`
