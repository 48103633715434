import React, { useState } from 'react'
import Base, { components } from 'react-select'

import { Wrapper, Title, Icon } from './index.styled'
import { calculateResponsiveSize, calculateTabletResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'
import useWindowSize from 'helpers/use-window-size'

const defaultStyles = () => ({
  control: (provided) => ({
    ...provided,
    width: '100%',
    height: `${calculateResponsiveSize(26)}`,
    minHeight: `${calculateResponsiveSize(26)}`,
    // fontFamily: "'Graphik'",
    fontSize: `${calculateResponsiveSize(12)}`,
    color: '#000000',
    borderRadius: '0',
    border: '0',
    borderBottom: '1px solid #000000 !important',
    background: 'none',
    boxShadow: 'none',
    outline: 'none',
    cursor: 'pointer',
    zIndex: '5',
    '.error &': {
      borderBottomColor: '#CD3B34 !important'
    },
    [mediaMax('desktopSmall')]: { height: `${calculateTabletResponsiveSize(26)}`, minHeight: `${calculateTabletResponsiveSize(26)}`, fontSize: `${calculateTabletResponsiveSize(12)}` },
    [mediaMax('mobile')]: { height: '26px', minHeight: '26px', fontSize: '12px' }
  }),
  dropdownIndicator: () => ({
    zIndex: '-1',
    display: 'flex',
    justifyContent: 'center',
    width: '10px',
    padding: '0'
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
    padding: `0 ${calculateResponsiveSize(6)}`,
    // fontFamily: "'Graphik'",
    fontSize: `${calculateResponsiveSize(12)}`,
    [mediaMax('desktopSmall')]: { padding: `0 ${calculateTabletResponsiveSize(6)}`, fontSize: `${calculateTabletResponsiveSize(12)}` },
    [mediaMax('mobile')]: { padding: '0 6px', fontSize: '12px' }
  }),
  menuList: (provided) => ({
    ...provided,
    height: '148px',
    padding: '6px 0',
    borderRadius: '0',
    border: '0'
    // background: "#FFF6F0",
  }),
  menu: (provided, state) => {
    return {
      ...provided,
      height: '0px',
      margin: '0',
      borderRadius: '0',
      border: '1px solid #000000',
      borderTop: '0',
      background: '#FFF6F0',
      boxShadow: 'none',
      visibility: 'hidden',
      overflow: 'hidden',
      opacity: 1,
      transition: 'all 0.5s ease-in-out',
      zIndex: '12'
    }
  },
  option: (provided) => ({
    ...provided,
    borderBottom: 'none',
    background: 'transparent',
    padding: '6px 10px',
    // fontFamily: "'Graphik'",
    fontSize: '12px',
    color: '#000000',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.20)',
      boxShadow: 'none',
      color: '#000000'
    },
    '&:active': {
      backgroundColor: 'transparent'
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    // fontFamily: "'Graphik'",
    color: '#000000',
    fontStyle: 'italic',
    opacity: '.5'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0'
  }),
  container: (provided) => ({
    ...provided
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 3
  }),
  singleValue: (provided) => ({
    ...provided,
    // fontFamily: "'Graphik'",
    color: '#000000'
  })
})

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon className='select-icon' />
      </components.DropdownIndicator>
    )
  )
}

const Input = ({ title, required = false, className, error, ...props }) => {
  const [open, setOpen] = useState(false)
  const { isMobile } = useWindowSize()

  const openMenuHandler = async (name) => {
    await setOpen(true)

    const menu = document.querySelector(`#select-${name} .menu`)
    if (menu) {
      if (props.name === 'state' || props.name === 'hearAbout') {
        menu.style.height = '148px'
      } else if (props.name === 'purchasingOrRenting' || props.name === 'type') {
        menu.style.height = '94px'
      } else {
        menu.style.height = isMobile ? '134px' : '120px'
      }
      menu.style.visibility = 'visible'

      document.querySelector(`#select-${name} .select-icon`).style.transform =
        'rotate(180deg)'
    }
  }

  const closeMenuHandler = (name) => {
    const menu = document.querySelector(`#select-${name} .menu`)
    if (menu) {
      menu.style.height = '0px'
      menu.style.visibility = 'hidden'
      document.querySelector(`#select-${name} .select-icon`).style.transform =
        'rotate(0deg)'
    }

    setTimeout(() => {
      setOpen(false)
    }, 500)
  }
  //  console.dir(props.name)
  return (
    <Wrapper className={[className].join(' ')}>
      {title && (
        <Title className={error && 'error'}>
          {title}
          {props.rules?.required && '*'}
        </Title>
      )}
      <Base
        {...props}
        id={`select-${props.name}`}
        isSearchable={false}
        className={[error && 'error'].join(' ')}
        styles={defaultStyles()}
        menuIsOpen={open}
        menuShouldScrollIntoView={false}
        components={{
          DropdownIndicator,
          Menu: (props) => <components.Menu {...props} className='menu' />
        }}
        onMenuOpen={() => {
          openMenuHandler(props.name)
        }}
        onMenuClose={() => {
          closeMenuHandler(props.name)
        }}
      />
    </Wrapper>
  )
}

export default Input
