import React, { useRef } from 'react'
import {
  Wrapper,
  Top,
  LegalLogo,
  ButtonBack,
  LegalContent,
  Title,
  Cols,
  Col
} from './index.styled'

const Legal = () => {
  const legalRef = useRef(null)

  const toDo = () => {
    legalRef.current.classList.remove('open')
    document.body.classList.toggle('hidden', legalRef.current.classList.contains('open'))
  }

  const scrollToTop = () => {
    toDo()
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const onBack = () => {
    toDo()
  }

  return (
    <Wrapper id='legal' ref={legalRef}>
      <Top>
        <LegalLogo onClick={scrollToTop} />
        <ButtonBack onClick={onBack}>Back</ButtonBack>
      </Top>
      <LegalContent>
        <Title>LEGAL</Title>
        Real Estate Consulting and Sales by Optima Realty, Inc. (“we”, “us”). A licensed Arizona Broker ADRE #CO548458000.<br /><br />
        <strong>Feature/Finishes</strong>
        Features, amenities, floor plans, elevations, square footage and designs vary per plan and are subject to changes or substitution without prior notice. Actual finishes and materials installed in homes may differ from the materials depicted on this website or in the sales center. Natural materials like stone or wood typically are inconsistent and a variety of imperfections may exist. These inconsistencies and imperfections are normal and to be expected.<br /><br />
        <strong>Floor Plans</strong>
        Plans and elevations are artist’s renderings only, may not accurately represent the actual condition of a home as constructed, and may contain options or furnishings which are not standard. We reserve the right to make changes to pricing, floor plans, specifications, dimensions and elevations without prior notice. Stated dimensions and square footage are approximate and should not be used as representation of the home’s precise or actual size. Any statement, verbal or written, regarding the square footage size of any home has been estimated and should not be construed to indicate certainty. Garage and/or parking space sizes depicted may vary from the final product and may not accommodate all vehicles.<br /><br />
        <strong>Human Models/Lifestyle Photos</strong>
        Models/lifestyle photos do not reflect age, racial or ethnic preference.<br /><br />
        <strong>Intellectual Property</strong>
        This website and all materials contained herein, including but not limited to floor plans, images and elevations, are copyrighted and may not be distributed, modified, or reproduced in whole or in part without prior written permission. This website is provided as a service to Internet users and its use is a privilege to the viewer. All information is provided without warranty of any kind, either express or implied or for non-infringement. Access and use of the website and all company links are subject to the terms and conditions set forth herein and to all applicable laws regulating the use of the Internet and those relating to the use of information. The use of this website is also subject to all applicable laws involving copyrighted materials and intellectual property.<br /><br />
        <strong>Maps and Images</strong>
        Site plans, community maps, photos and/or computer-generated renderings have been posted on this site for illustration purposes only. All site plans, community maps, photos and computer-generated renderings shown are conceptual in nature and are merely an artist’s rendition. They may not accurately represent the actual condition of the item being represented. All photos/videos shown depict the community or home as of the date taken. All Illustrations are solely for illustrative purposes and should never be relied upon.<br /><br />
        <strong>Privacy Policy</strong>
        In order to protect your privacy, we provide the following information below regarding our online information policies. This privacy statement discloses the privacy practices for Optima Realty, Inc. (“we”, “us”) and any of its related entities. The operation of our Website www.optimamcdowellmountain.com (the “Site”) involves the collection and use of information about you. This Privacy Policy ( “Privacy Policy”) explains the type of information we collect and how we use it, so that you understand your options regarding the collection and use of your information.<br />
        We strive to build trust and confidence with customers by utilizing best practices on the Internet. Our goal to manage information from our site is always one of putting our customers first. This philosophy dictates that we carefully determine how we use your private and confidential information with our related companies and certain third-party vendors, and at the time you may opt-out. By visiting or using our website, services, or products, you agree to the terms of this privacy policy. If you do not agree with this privacy policy, please do not use our website, services, or products.<br />
        If you feel that we are not abiding by this privacy policy, please contact our corporate webmaster by sending an email to optimamcdowell@optima.inc.<br /><br />
        <strong>Types of Information Collected</strong>
        This policy applies to all information collected or submitted on our web sites. There are two categories of information: Anonymous and Personally Identifiable Information (“PII”).<br />
        Anonymous Information is information that cannot be tied to an identifiable customer. Examples include general web site traffic information such as total number of customers visiting our site, or suggestion box submissions where submitting information is anonymized.<br />
        PII is information that tells us specifics about you, such as name, email, phone number, and zip code. It is described in applicable state privacy laws, and is information that can be used on its own or with other information to reasonably identify, contact, or locate a single person, or to identify an individual in context. We use this to deliver personalized information to you. An example is the Interest List www.optimamcdowellmountain.com, which keeps customers informed about upcoming opportunities.<br />
        We collect PII from you when you:
        <ul>
          <li>Register or create an account on our website</li>
          <li>Fill out a form or provide feedback on our website</li>
          <li>Subscribe to our newsletter or marketing communications</li>
          <li>Contact us via email, phone, or social media</li>
          <li>Use our services or products, such as searching for or listing properties, making inquiries, or completing transactions</li>
        </ul>
        The PII we collect from you may include:
        <ul>
          <li>Your name, email address, phone number, and password</li>
          <li>Your location, preferences, interests, and feedback</li>
          <li>Your property information, such as address, price, features, and photos</li>
          <li>Your payment information, such as credit card number, billing address, and transaction history</li>
          <li>Your device information, such as IP address, browser type, operating system, and device identifier</li>
          <li>Customer service information and feedback, such as when you request customer support or information from us, provide feedback or reviews about your experience with us or our products, or otherwise communicate with, or contact us</li>
          <li>Your usage information, such as pages visited, time spent, and actions taken on our website (including login attempts, logout events, access times, pages and data viewed, links clicked, and the page you visited before navigating to our Website)</li>
        </ul>
        <br />
        <strong>Use of Personal Information</strong>
        We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, apply for employment, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:
        <ul>
          <li>To provide you with our website, services, and products, and to improve them</li>
          <li>To communicate with you and respond to your inquiries, requests, or complaints</li>
          <li>To personalize your experience and tailor our website, services, and products to your needs and preferences</li>
          <li>To send you newsletters, marketing communications, promotions, surveys, email, direct mail, retargeting, IP targeting, analytics or other information that may be of interest to you</li>
          <li>To process your payments and transactions, and to prevent fraud or other illegal activities</li>
          <li>To comply with legal obligations, enforce our terms and conditions, and protect our rights and interests</li>
          <li>For any purpose not in violation of applicable laws.</li>
        </ul>
        <br />
        We do not sell or rent your contact information to other marketers. There are times when it may be advantageous for us to share certain personal information about you to companies that we have a strategic relationship with or that perform work for us to provide services and products to you on our behalf. These companies may help us process information, answer questions, deliver services and products to you, manage and enhance customer data, provide customer service, assess your interest in our products and services, or conduct customer research or satisfaction surveys. We may disclose information with data analytics vendors or market research companies. In some circumstances these entities may use your information for their own legitimate business purposes. This might include internal analytics but would not include marketing directly to you. These companies are also obligated to protect your personal information in accordance with our policies. Without such information being made available, it might be difficult for you to purchase service and products, have products delivered to you, receive customer service, provide us feedback to improve our services and products, or access certain services, offers, and content on the site. We may share your PII with third parties in the following situations:
        <ul>
          <li>With your consent or at your direction or where permitted by law and for reasons described to you.</li>
          <li>With other users or third parties who are involved in your use of our website, services, or products, such as property owners, buyers, agents, or service providers</li>
          <li>With our affiliates, subsidiaries, or partners who help us provide or improve our website, services, or products</li>
          <li>With our service providers who perform functions on our behalf, such as hosting, analytics, payment processing, marketing, or customer service</li>
          <li>With law enforcement, regulators, courts, or other authorities when required or permitted by law, or when necessary to protect our rights and interests</li>
          <li>With other parties in connection with a merger, acquisition, sale, or other transfer of our assets or business</li>
          <li>We may disclose aggregate, anonymous, or de-identified information that cannot be reasonably used to identify you.</li>
        </ul>
        <br />
        <strong>Cookies and Other Tracking Technologies</strong>
        We engage others to provide analytics services across the web including the use of cookies and other tracking technologies, such as web beacons, pixels, or scripts, to collect and store information about your device, browser, and usage when you visit or use our website, services, or products. Cookies are small files that are stored on your device by your web browser. They allow us to recognize you and remember your preferences, enhance your user experience, and analyze our website performance and traffic.<br />
        You can manage your cookie preferences by changing your browser settings. You can choose to accept, reject, or delete cookies, or to receive a notification when a cookie is set. However, some features or functions of our website, services, or products may not work properly or at all if you disable or delete cookies.<br />
        We may also use third-party cookies or tracking technologies, such as Google Analytics, to help us measure and improve our website, services, or products. These third parties may collect and use your PII in accordance with their own privacy policies, which we do not control. You can opt out of Google Analytics by installing the Google Analytics opt-out browser add-on.<br /><br />
        <strong>Third-party Links</strong>
        You should be aware that when you are on our website you could be directed to other third-party sites that are beyond our control. There are links to other sites, which take you outside our service. These other sites may include sites of other advertisers, sponsors, and partners. These other sites may send their own cookies to visitors to collect data or solicit information. We do not control these sites and therefore are not responsible for their content. The inclusion of hyperlinks to any other sites by us does not imply any endorsement of the material on such sites, nor any association with their operators.<br />
        Our policies do not extend to anything that is inherent in the operation of the Internet, which is beyond our control.<br /><br />
        <strong>Choice/Opt-Out and How to Contact Us</strong>
        Customers who no longer wish to receive our materials may opt-out of receiving by replying with "unsubscribe" in the subject line of the email, or email us at optimamcdowell@optima.inc<br /><br />
        <strong>Retention of Data</strong>
        We make reasonable efforts to ensure a level of security appropriate to the risk associated with the processing of personal data. We maintain organizational, technical and administrative measures designed to protect personal data within our organization against unauthorized access, destruction, loss, alteration or misuse. Your personal data is only accessible to a limited number of personnel who need access to the information to perform their duties. No data transmission or storage system can be guaranteed to be 100% secure. If you believe that your interaction with us is no longer secure please contact us via the contact information in the Contact Us section of this policy.<br />
        We retain personal data to the extent necessary to comply with our legal and regulatory obligations, and for the purpose of fraud monitoring, detection, and prevention. Where we retain data, we do so in accordance with obligations imposed by applicable law and under this Privacy Policy.<br /><br />
        <strong>Notification of Changes</strong>
        If we decide to change our privacy policy, we will post those changes on our home pages. We want our customers to be aware of what information we collect and how we use it. Our customers will have a choice as to whether or not we can use their information in this different manner. We will use information in accordance with the privacy policy under which the information was collected.<br /><br />
        <strong>ADDITIONAL DISCLOSURES FOR INDIVIDUALS RESIDING IN CERTAIN U.S. STATES</strong>
        Certain U.S. states including California, Colorado, Connecticut, Indiana, Iowa, Montana, Oregon, Tennessee, Texas, Utah, and Virginia have enacted consumer privacy laws that grant their residents certain rights and require additional disclosures (“State Laws”). If you are a resident of one of these states and the State Law is applicable to Optima Realty, Inc., this section applies to you.<br /><br />
        Our Privacy Policy explains how we collect, use, and disclose information about you, as well as our targeted advertising and analytics practices. Below, we use two different tables to explain this same information in accordance with the State Laws. The tables describe our data practices now, and over the past 12 months.<br /><br />
        <Cols>
          <Col>
            <strong>Cookies and Other Tracking Technologies</strong>
            <br />
            <ul>
              <li>Identifiers, such as your contact and account information, name, mailing address, and email address</li>
              <li>Commercial information</li>
              <li>Internet or other electronic network activity information</li>
              <li>Inferences</li>
            </ul>
          </Col>
          <Col>
            <strong>Categories of Recipients</strong>
            <br />
            <br />
            <ul>
              <li>Fraud prevention partners, data analytics providers, marketing partners, customer support partners, Internet service providers, operating systems and platforms, customer feedback platforms, and cloud service providers</li>
            </ul>
          </Col>
          <Col>
            <strong>Cookies and Other Tracking Technologies</strong>
            <br />
            <ul>
              <li>Provide, maintain, improve, and develop our products and services;</li>
              <li>Personalize your experience with us;</li>
              <li>Send you technical notices, security alerts, support messages and other transactional or relationship messages;</li>
              <li>Monitor and analyze trends, usage, and activities in connection with our products and services;</li>
              <li>Facilitate contests, sweepstakes, and promotions and process and deliver entries and rewards;</li>
              <li>Detect, investigate, and prevent security incidents and other malicious, deceptive, fraudulent, or illegal activity and protect the rights and property of Optima Realty, Inc. and others; and</li>
              <li>Comply with our legal and financial obligations.</li>
            </ul>
          </Col>
        </Cols>
        <br />
        We retain personal information for as long as necessary to carry out the purposes for which we originally collected it and for other purposes described in this privacy policy.<br /><br />
        We do not use or disclose sensitive personal information for the purpose of inferring characteristics about you.<br /><br />
        <strong>Personal Information that we “Share,” “Sell,” or Use for “Targeted Advertising”</strong>
        We disclose the following categories of personal information to third parties to engage in targeted advertising and other marketing activities, including to expand the reach and effectiveness of our own marketing campaigns and for third parties’ own marketing purposes. These disclosures may be considered “sales” or “sharing” or use of personal information for “targeted advertising” under certain State Laws.<br /><br />
        <table>
          <thead>
            <tr>
              <th>Categories of Personal Information</th>
              <th>Categories of Recipients</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Identifiers</td>
              <td>Advertising and marketing networks, other brands in our corporate family, data co-ops, data brokers</td>
            </tr>
            <tr>
              <td>Characteristics of protected classifications under California or U.S. federal law</td>
              <td>Advertising and marketing networks, other brands in our corporate family</td>
            </tr>
            <tr>
              <td>Commercial information</td>
              <td>Advertising and marketing networks, other brands in our corporate family, data co-ops, data brokers</td>
            </tr>
            <tr>
              <td>Internet and electronic network activity</td>
              <td>Advertising and marketing networks, other brands in our corporate family</td>
            </tr>
            <tr>
              <td>Inferences drawn from your personal information to create a profile</td>
              <td>Advertising and marketing networks, other brands in our corporate family</td>
            </tr>
          </tbody>
        </table>
        <br />
        <strong>Your Privacy Rights</strong>
        <br />
        <strong>Opt Out of Sales, Sharing, and Targeted Advertising</strong>
        We disclose the following categories of personal information to third parties to engage in targeted advertising and other marketing activities, including to expand the reach and effectiveness of our own marketing campaigns and for third parties’ own marketing purposes. These disclosures may be considered “sales” or “sharing” or use of personal information for “targeted advertising” under certain State Laws.<br /><br />
        Some of the activities described in the Targeted Advertising and Analytics section above may be considered “sales” or “sharing” of your personal information or use of your information for “targeted advertising” under the law that applies to you. You can opt out of cookie-based ad targeting on our website by emailing us at optimamcdowell@optima.inc. You will need to renew your opt-out choice if you visit our website from a new device or browser or if you clear your cookies because your opt-out choice will be linked to your browser only.<br /><br />
        Do Not Track is an optional setting that enables you to express your preferences regarding the collection of information about your online activities over time and across third-party websites. Your browser sends Do Not Track signals to the websites you visit expressing your preference not to be tracked. However, because there is no industry-standard approach to responding to Do Not Track signals, We do not process or respond to Do Not Track signals.<br /><br />
        In addition to cookie-based ad targeting, we may disclose information about your purchases and contact information (such as email and mailing address) to our affiliates, advertising partners, and other third parties for marketing and advertising purposes, which may constitute “sales” or “sharing” of your personal information or use of your information for “targeted advertising.” You can opt out of having your information disclosed to these third parties by contacting us.<br /><br />
        <strong>Access, Correction, and Deletion</strong>
        Depending on where you live, you may have the right to (1) request to know more about and access your personal information, including in a portable format, (2) request deletion of your personal information, and (3) request correction of inaccurate personal information. To request access, correction, or deletion of your personal information, please email us at optimamcdowell@optima.inc. We will verify your request by asking you to provide certain information, such as your name, email address, mailing address, and phone number.<br /><br />
        <strong>Appeals</strong>
        If we deny your request, you may appeal our decision by contacting us at optimamcdowell@optima.inc. If you have concerns about the results of an appeal, you may contact the attorney general in the state where you reside.<br /><br />
        <strong>Nondiscrimination</strong>
        We will not discriminate against you for exercising your privacy rights.<br /><br />
        If you reside in certain states, you can designate an authorized agent to submit a privacy rights request on your behalf. We may ask authorized agents to submit proof of your authorization to make the request, such as a valid power of attorney or proof that they have signed permission from the individual who is the subject of the request. In some cases, we may be required to contact the individual who is the subject of the request to verify his or her own identity or confirm the authorized agent has permission to submit this request. If you are an authorized agent seeking to make a request, please contact us at optimamcdowell@optima.inc.<br /><br />
        Any request to exercise one of these rights will be assessed by Optima Realty, Inc. on a case-by-case basis. There may be circumstances in which we are not legally required to comply with your request because of a relevant legal exemption provided for under applicable law. We will not discriminate or retaliate against you for exercising your privacy rights. If you choose not to disclose certain personal information, this may limit our ability to perform the Services to you.<br /><br />
        <strong>“Sales” and “Sharing” of Personal Information</strong>
        We share your information with third party clients in order to provide the Service. We do not have any knowledge of any “sales” or “sharing” of the personal information of minors under 16 years of age. You have the right to opt out of sharing and sales at any time by contacting us at optimamcdowell@optima.inc. Please see the “Your Privacy Rights and Choices” section above for more information about your privacy rights and how to exercise them.<br /><br />
        <strong>Notice of Financial Incentives</strong>
        We provide discounts, rewards, or other benefits to customers who download our mobile app or sign up to receive our marketing emails or text messages. These offerings may constitute “financial incentives” or “bona fide loyalty programs” under certain state privacy laws (for simplicity, we refer to these collectively as “financial incentives”). If you participate in a financial incentive, we collect personal information from you, such as identifiers (like your name, email address, and phone number), commercial information (like your purchase history), and inferences drawn from your personal information (like your product preferences and interests). We disclose this information to our service providers, contractors, and consultants who perform services on our behalf, to other brands in our corporate family, and to our marketing and advertising partners, including for the purpose of engaging in targeted advertising and other marketing activities.<br /><br />
        You can opt into a financial incentive by following the sign-up or participation instructions provided and you can opt out at any time by following the unsubscribe instructions in the applicable program’s terms and conditions, promotional emails, or text messages, or contacting us at optimamcdowell@optima.inc. If you request that we delete any of your personal information that is essential to providing you with the financial incentive, we will not be able to provide you with the benefits of that program (e.g., if you ask us to delete your email address, we will no longer be able to provide you with early access to sales or other benefits via email). In some cases, we may provide additional terms and conditions, which we will provide to you when you sign up. The value of your personal information is reasonably related to the value of the offer or discount presented to you.<br /><br />
        <strong>Shine the Light</strong>
        California’s Shine the Light law permits residents of California to request certain details about how their information is disclosed to third parties for direct marketing purposes. Under the law Optima Realty, Inc. must either provide this information or permit California residents to opt in to, or opt out of, this type of disclosure. Optima Realty, Inc. qualifies for this alternative option. To opt out of having information about you disclosed to our affiliates or other third parties for direct marketing purposes, please email us at optimamcdowell@optima.inc.<br /><br />
        Warning: the California Department of Real Estate has not inspected, examined, or qualified this offering.
      </LegalContent>
    </Wrapper>
  )
}

export default Legal
