import styled from 'styled-components'
import { calculateResponsiveSize, calculateTabletResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'
import SelectArrow from 'icons/select_arrow.svg'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
`

export const Title = styled.div`
  &.error {
    color: #EF3E42;
  }
`

export const Icon = styled.div`
  width: ${calculateResponsiveSize(9)};
  height: ${calculateResponsiveSize(7)};
  background: #000000;
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${SelectArrow});
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  -webkit-mask-image: url(${SelectArrow});
  transition: all 0.5s ease-in-out;
  .error & {
    background: #EF3E42;
  }
  ${mediaMax('desktopSm')} {
    width: ${calculateTabletResponsiveSize(9)};
    height: ${calculateTabletResponsiveSize(7)};
  }
  ${mediaMax('mobile')} {
    width: 9px;
    height: 7px;
  }
`
