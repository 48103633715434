import React, { useRef } from 'react'
import {
  HeaderWrapper,
  HeaderCenter,
  HeaderLogo,
  List,
  ListItem,
  Item,
  ButtonInquire,
  ButtonMenu,
  Close
} from './index.styled'
import Menu from 'components/header/menu'
import Inquire from 'components/header/inquire'
import useWindowSize from 'helpers/use-window-size'
import { links } from './links'

const Header = ({ className }) => {
  const { width, isMobile } = useWindowSize()
  const headerRef = useRef(null)

  // click on logo
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  // for desktop menu
  const handleItemClick = (url) => {
    const currentHash = window.location.hash
    const itemHash = url.substring(url.indexOf('#'))

    if (currentHash === itemHash) {
      const timeout = setTimeout(() => {
        const targetElement = document.querySelector(`${itemHash}-section`)

        if (targetElement) {
          const offsetTop = targetElement.offsetTop
          window.scrollTo({
            top: offsetTop,
            behavior: 'smooth'
          })
        }
      }, 100)
      return () => clearTimeout(timeout)
    }

    window.location.href = url
  }

  const inquireOnClick = (event) => {
    const clickedElement = event.currentTarget
    const menu = document.querySelector('#menu')
    const inquire = document.querySelector('#inquire')
    const buttonMenu = document.querySelector('.button-menu')

    if (isMobile) {
      menu.classList.remove('open')
      buttonMenu.classList.remove('active')
    }

    inquire.classList.toggle('open')
    document.body.classList.toggle('hidden', inquire.classList.contains('open'))
    headerRef.current.classList.toggle('open', inquire.classList.contains('open'))
    clickedElement.classList.toggle('active', inquire.classList.contains('open'))
  }

  // for button mobile menu
  const menuOnClick = (event) => {
    const clickedElement = event.currentTarget
    const menu = document.querySelector('#menu')
    const inquire = document.querySelector('#inquire')
    const buttonInquire = document.querySelector('.button-inquire')

    inquire.classList.remove('open')
    buttonInquire.classList.remove('active')

    menu.classList.toggle('open')
    document.body.classList.toggle('hidden', menu.classList.contains('open'))
    headerRef.current.classList.toggle('open', menu.classList.contains('open'))
    clickedElement.classList.toggle('active', menu.classList.contains('open'))
  }

  return (
    <>
      <HeaderWrapper id='header' ref={headerRef}>
        <HeaderCenter id='header-center' className={className}>
          <HeaderLogo onClick={scrollToTop} />
          {!isMobile && (
            <List>
              {links.map((item, index) => {
                return (
                  <ListItem key={index}>
                    <Item onClick={() => handleItemClick(item.url)}>
                      {item.text}
                    </Item>
                  </ListItem>
                )
              })}
            </List>
          )}
          <ButtonInquire className='button-inquire' onClick={inquireOnClick}>
            INQUIRE{isMobile && <Close>Close</Close>}
          </ButtonInquire>
          {isMobile && (
            <ButtonMenu className='button-menu' onClick={menuOnClick}>
              Menu<Close>Close</Close>
            </ButtonMenu>
          )}
        </HeaderCenter>
      </HeaderWrapper>
      {width <= 768 && (
        <>
          <Menu />
          <Inquire />
        </>
      )}
    </>
  )
}

export default Header
