import React from 'react'
import { Wrap, Label, Title, Input, InputWrap } from './index.styled'

const InputField = ({ checked, onChange, title, type }) => (
  <Wrap className={[checked ? 'checked' : '', type].join(' ')}>
    <Label>
      <InputWrap>
        <Input type='radio' checked={checked} onChange={onChange} />
        <span className={checked ? 'checked' : ''} />
      </InputWrap>
      <Title>{title}</Title>
    </Label>
  </Wrap>
)

export default InputField
