import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { GroupItem } from './index.styled'

import Input from './input'

const RadioInput = ({ name, defaultValue = [], options, ...props }) => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      render={({ field: { onChange, value } }) =>
        Object.keys(options).map((val, key) => (
          <GroupItem key={key} className={props.className}>
            <Input
              title={options[val]}
              checked={val === value}
              onChange={() => onChange(val)}
              {...props}
            />
          </GroupItem>
        ))}
      name={name}
      defaultValue={defaultValue}
    />
  )
}

RadioInput.displayName = 'RadioInput'

export default RadioInput
