import styled from 'styled-components'
import { calculateResponsiveSize, calculateTabletResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'
import Bg from 'images/home/footer_mobile.jpg'
import { ButtonStyles } from 'components/header/index.styled'

export const FooterWrapper = styled.footer`
  padding: ${calculateResponsiveSize(20)};
  padding-top: 120vh !important;
  position: relative;
  z-index: 1;
  overflow: hidden;
  ${mediaMax('desktopSm')} {
    padding: ${calculateTabletResponsiveSize(20)};
  }
  ${mediaMax('mobile')} {
    padding: 0;
    padding-top: 100vh !important;
  }
`

export const FooterCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${calculateResponsiveSize(30)} ${calculateResponsiveSize(20)} ${calculateResponsiveSize(20)};
  font-size: ${calculateResponsiveSize(12)};
  color: #ffffff;
  line-height: 1.6;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  position: relative;
  z-index: 2;
  a {
    color: #ffffff;
  }
  ${mediaMax('desktopSm')} {
    padding: ${calculateTabletResponsiveSize(30)} ${calculateTabletResponsiveSize(20)} ${calculateTabletResponsiveSize(20)};
    font-size: ${calculateTabletResponsiveSize(12)};
  }
  ${mediaMax('mobile')} {
    gap: 15px;
    padding: 30px 20px 60px;
    font-size: 12px;
    border-radius: 0;
  }
`

export const Row = styled.div`
  display: flex;
  gap: ${calculateResponsiveSize(40)};
  width: 100%;
  ${mediaMax('desktopSm')} {
    gap: ${calculateTabletResponsiveSize(40)};
  }
  ${mediaMax('mobile')} {
    flex-direction: column;
    gap: 30px;
  }
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  &.col-0 {
    width: 31%;
    max-width: ${calculateResponsiveSize(500)};
  }
  &.col-1 {
    width: 24%;
    max-width: ${calculateResponsiveSize(400)};
    margin: 0 0 0 auto;
  }
  &.col-2 {
    width: 23%;
    max-width: ${calculateResponsiveSize(400)};
  }
  img.map {
    height: ${calculateResponsiveSize(164)};
    margin-top: ${calculateResponsiveSize(20)};
  }
  img.logo {
    height: ${calculateResponsiveSize(18)};
    margin-bottom: ${calculateResponsiveSize(12)};
  }
  ${mediaMax('desktopSm')} {
    &.col-0 {
      max-width: ${calculateTabletResponsiveSize(500)};
    }
    &.col-1 {
      max-width: ${calculateTabletResponsiveSize(400)};
    }
    &.col-2 {
      max-width: ${calculateTabletResponsiveSize(400)};
    }
    img.map {
      height: ${calculateTabletResponsiveSize(164)};
      margin-top: ${calculateTabletResponsiveSize(20)};
    }
    img.logo {
      height: ${calculateTabletResponsiveSize(18)};
      margin-bottom: ${calculateTabletResponsiveSize(12)};
    }
  }
  ${mediaMax('mobile')} {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    img.map {
      display: block;
      height: 202px;
      margin: 20px auto 0 auto;
    }
    img.logo {
      height: 18px;
      margin: 10px 0 12px 0;
    }
  }
`

export const RowInn = styled.div`
`

export const Title = styled.div`
  margin: 0 0 ${calculateResponsiveSize(15)} 0;
  font-family: "Didot";
  font-size: ${calculateResponsiveSize(30)};
  line-height: 1.2;
  letter-spacing: ${calculateResponsiveSize(-0.6)};
  ${mediaMax('desktopSm')} {
    margin: 0 0 ${calculateTabletResponsiveSize(15)} 0;
    font-size: ${calculateTabletResponsiveSize(30)};
    letter-spacing: ${calculateTabletResponsiveSize(-0.6)};
  }
  ${mediaMax('mobile')} {
    margin: 0 0 10px 0;
    font-size: 24px;
    letter-spacing: -0.48px;
  }
`

export const Text = styled.div`
  .col-0 & {
    max-width: ${calculateResponsiveSize(350)};
  }
  ${mediaMax('desktopSm')} {
    .col-0 & {
      max-width: ${calculateTabletResponsiveSize(350)};
    }
  }
  ${mediaMax('mobile')} {
    max-width: 100% !important;
    .col-1 &, .col-2 &  {
      position: absolute;
      top: 7px;
      right: 0;
    }
  }
`

export const Button = styled.div`
  ${ButtonStyles}
  width: ${calculateResponsiveSize(186)};
  margin-top: ${calculateResponsiveSize(24)};
  ${mediaMax('desktopSm')} {
    width: ${calculateTabletResponsiveSize(186)};
    margin-top: ${calculateTabletResponsiveSize(24)};
  }
  ${mediaMax('mobile')} {
    width: 155px;
    margin-top: 16px;
  }
`

export const Copy = styled.div`
  max-width: ${calculateResponsiveSize(254)};
  font-size: ${calculateResponsiveSize(9)};
  ${mediaMax('desktopSm')} {
    max-width: ${calculateTabletResponsiveSize(254)};
    font-size: ${calculateTabletResponsiveSize(9)};
  }
  ${mediaMax('mobile')} {
    max-width: 100%;
    font-size: 9px;
  }
`

export const ButtonLegal = styled.div`
  margin-top: ${calculateResponsiveSize(10)};
  line-height: 1.4;
  letter-spacing: ${calculateResponsiveSize(0.6)};
  text-transform: uppercase;
  cursor: pointer;
  ${mediaMax('desktopSm')} {
    margin-top: ${calculateTabletResponsiveSize(10)};
    letter-spacing: ${calculateTabletResponsiveSize(0.6)};
  }
  ${mediaMax('mobile')} {
    margin-top: 10px;
    letter-spacing: 0.6px;
  }
`

export const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 1;
  .gatsby-image-wrapper {
    height: 100%;
  }
  ${mediaMax('mobile')} {
    position: fixed;
    background: url(${Bg}) top center no-repeat;
    background-size: cover;
    opacity: 0;
    &.visible {
      opacity: 1;
    }
  }
`
