export const selectPurchasingOrRenting = [
  { value: 'Purchase', label: 'Purchase' },
  { value: 'Rent', label: 'Rent' },
  { value: 'Commercial Space', label: 'Commercial Space' }
]
export const selectHearAbout = [
  { value: 'Brokers Tour', label: 'Brokers Tour' },
  { value: 'Print Ad', label: 'Print Ad' },
  { value: 'Direct Mail', label: 'Direct Mail' },
  { value: 'Email', label: 'Email' },
  { value: 'Social Media', label: 'Social Media' },
  { value: 'Online Ad', label: 'Online Ad' },
  { value: 'Home Search Portal', label: 'Home Search Portal' },
  { value: 'Press Release', label: 'Press Release' },
  { value: 'Signage', label: 'Signage' },
  { value: 'Drive or Walk By', label: 'Drive or Walk By' },
  { value: 'Agent/Broker Referral', label: 'Agent/Broker Referral' },
  { value: 'Friend/Family Referral', label: 'Friend/Family Referral' },
  { value: 'Existing Optima Resident', label: 'Existing Optima Resident' }
]
export const selectBedroom = [
  { value: 'One Bedroom', label: 'One Bedroom' },
  { value: 'Two Bedroom', label: 'Two Bedroom' },
  { value: 'Three Bedroom', label: 'Three Bedroom' },
  { value: 'Custom Combination Home', label: 'Custom Combination Home' }
]
