import React, { useEffect } from 'react'
import {
  MainIntroWrapper,
  LogoWrapper,
  LogoLeftWrapper,
  LogoRightWrapper
} from './index.styled'
import LogoLeft from 'icons/logo_intro_left.svg'
import LogoRight from 'icons/logo_intro_right.svg'

const MainIntro = ({ setIsFirstLoad = () => {} }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo({ top: 0 })
    }, 100)

    document.body.classList.add('hidden')

    const timeout2 = setTimeout(() => {
      document.body.classList.remove('hidden')
      setIsFirstLoad(false)
    }, 6000)

    return () => {
      clearTimeout(timeout)
      clearTimeout(timeout2)
    }
  }, [])

  return (
    <>
      <MainIntroWrapper>
        <LogoWrapper>
          <LogoLeftWrapper>
            <img src={LogoLeft} alt='' />
          </LogoLeftWrapper>
          <LogoRightWrapper>
            <img src={LogoRight} alt='' />
          </LogoRightWrapper>
        </LogoWrapper>
      </MainIntroWrapper>
    </>
  )
}

export default MainIntro
