import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Transition, TransitionGroup } from 'react-transition-group'
import { gsap, ScrollSmoother, ScrollTrigger } from 'helpers/gsap'
import MainIntro from 'components/ui/main-intro'
import Header from 'components/header'
import Inquire from 'components/header/inquire'
import Footer from 'components/footer'
import Legal from 'components/ui/legal'
import useWindowSize from 'helpers/use-window-size'

import 'helpers/fonts.css'
import GlobalStyle from 'helpers/global-styles'
import { LayoutWrapper, PageWrapper, ContentWrapper } from './index.styled'
import CookiesBanner from 'components/ui/cookies-banner'

const LayoutContent = ({
  children,
  location,
  bindScrollTriggerToFadedElements
}) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 1000, exit: 1000 }}>
        {(status) => (
          <div
            className={`page ${status}`}
            onAnimationEnd={() => {
              if (status === 'entered') {
                bindScrollTriggerToFadedElements()
              }
            }}
          >
            <LayoutWrapper>{children}</LayoutWrapper>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}

const Layout = (props) => {
  const {
    children,
    location,
    pageContext: { layout }
  } = props
  const isHomePage = location.pathname === '/'
  const { width } = useWindowSize()
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  // ScrollSmoother
  const scrollerSmoother = React.useRef(null)
  const refWrapper = React.useRef(null)
  React.useLayoutEffect(() => {
    const gsapContext = gsap.context(() => {
      if (width > 768) {
        scrollerSmoother.current = ScrollSmoother.create({
          content: '.smooth-content',
          wrapper: '.smooth-wrapper',
          smooth: 2,
          effects: true
        })

        // header-pin
        ScrollTrigger.create({
          trigger: '#header-center.header-in-content',
          start: 'top top',
          end: '+=100000%',
          toggleClass: {
            targets: '#header-center',
            className: 'header-pin',
            toggle: 'add'
          },
          once: false
        })

        /*  //pin
        gsap.utils.toArray('.pin').forEach((container) => {
          const pinId = container.id + '-pin'
          ScrollTrigger.create({
            trigger: container,
            pinSpacing: false,
            pin: true,
            start: 'bottom bottom',
            anticipatePin: 1,
            id: pinId,
          })
        }) */
      }
      if (width <= 768) {
        // footer-mobile-image
        ScrollTrigger.create({
          trigger: '#about-section',
          start: 'top top',
          end: '+=100000%',
          toggleClass: {
            targets: '#footer-image',
            className: 'visible',
            toggle: 'add'
          },
          once: false
        })
        ScrollTrigger.normalizeScroll({ allowNestedScroll: true })
      }
    }, refWrapper)

    return () => gsapContext.revert()
  }, [width, children])

  // Animations
  const bindScrollTriggerToFadedElements = () => {
    gsap.utils
      .toArray(
        '.animation, .fadeIn, .fadeInTitleFromBottom, .fadeInFromBottom, .slideInFromTop, .slideInFromLeft'
      )
      .forEach(function (container) {
        ScrollTrigger.create({
          trigger: container,
          start: 'top 90%',
          end: '+=1000%',
          toggleClass: {
            targets: container,
            className: 'visible',
            toggle: 'add'
          },
          once: true
        })
      })
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      bindScrollTriggerToFadedElements()
    }, 100)
    return () => clearTimeout(timeout)
  }, [children])

  // Scroll to anchor for desktop
  useEffect(() => {
    if (location.hash && width > 768) {
      const timeout = setTimeout(() => {
        const hashWithoutHash = location.hash.substring(1)
        const targetElement = document.querySelector(
          `#${hashWithoutHash}-section`
        )

        if (targetElement) {
          gsap.to(scrollerSmoother.current, {
            scrollTop: targetElement.offsetTop,
            duration: 2
          })
        }
      }, 100)
      return () => clearTimeout(timeout)
    }
  }, [location.hash, width])

  return (
    <>
      <div className='smooth-wrapper' ref={refWrapper}>
        {isHomePage && isFirstLoad && (
          <MainIntro setIsFirstLoad={setIsFirstLoad} />
        )}

        {width > 768 && (
          <>
            <Inquire />
            <Header className='header-in-wrapper' />
          </>
        )}
        <div className='smooth-content'>
          <Header className='header-in-content' />
          <PageWrapper>
            <GlobalStyle />
            <ContentWrapper>
              <LayoutContent
                location={location}
                bindScrollTriggerToFadedElements={
                  bindScrollTriggerToFadedElements
                }
              >
                {children}
              </LayoutContent>
            </ContentWrapper>
          </PageWrapper>
          <Footer />
        </div>
        <CookiesBanner />
        <Legal />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired
}

export default Layout
