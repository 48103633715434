import styled, { css } from 'styled-components'
import { calculateResponsiveSize, calculateTabletResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'
import Logo from 'icons/logo.svg'

export const HeaderWrapper = styled.header`
  position: absolute;
  top: 100svh;
  left: 0;
  right: 0;
  z-index: 100;
  ${mediaMax('mobile')} {
    height: auto;
  }
`

export const HeaderCenterStyles = css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(40)};
  width: 100%;
  padding: ${calculateResponsiveSize(38)} ${calculateResponsiveSize(142)};
  font-size: ${calculateResponsiveSize(12)};
  color: #ffffff;
  line-height: 1.4;
  letter-spacing: ${calculateResponsiveSize(0.6)};
  text-transform: uppercase;
  transition: padding 0.5s;
  &::before {
    position: absolute;
    top: ${calculateResponsiveSize(20)};
    bottom: ${calculateResponsiveSize(20)};
    left: ${calculateResponsiveSize(122)};
    right: ${calculateResponsiveSize(122)};
    content: "";
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    transition: left 0.5s, right 0.5s;
    z-index: -1;
  }
  .smooth-wrapper > header > &:not(.header-pin) {
    opacity: 0;
    visibility: hidden;
  }
  .smooth-wrapper > header > &.header-pin {
    position: fixed;
    top: 0;
    bottom: auto !important;
    padding: ${calculateResponsiveSize(38)} ${calculateResponsiveSize(40)};
    &::before {
      left: ${calculateResponsiveSize(20)};
      right: ${calculateResponsiveSize(20)};
    }
  }
  .smooth-content > header > &.header-pin {
    opacity: 0;
    visibility: hidden;
    padding: ${calculateResponsiveSize(38)} ${calculateResponsiveSize(40)};
    &::before {
      left: ${calculateResponsiveSize(20)};
      right: ${calculateResponsiveSize(20)};
    }
  }
  ${mediaMax('desktopSm')} {
    gap: ${calculateTabletResponsiveSize(40)};
    padding: ${calculateTabletResponsiveSize(38)} ${calculateTabletResponsiveSize(142)};
    font-size: ${calculateTabletResponsiveSize(12)};
    letter-spacing: ${calculateTabletResponsiveSize(0.6)};
    &::before {
      top: ${calculateTabletResponsiveSize(20)};
      bottom: ${calculateTabletResponsiveSize(20)};
      left: ${calculateTabletResponsiveSize(122)};
      right: ${calculateTabletResponsiveSize(122)};
    }
  }
  ${mediaMax('mobile')} {
    position: fixed;
    top: 0;
    bottom: auto;
    left: 0;
    right: 0;
    gap: 10px;
    padding: 15px 20px;
    font-size: 12px;
    letter-spacing: 0.6px;
    &::before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 0;
      .open & {
        background: rgba(0, 0, 0, 0);
      }
    }
  }
`

export const HeaderCenter = styled.div`
  ${HeaderCenterStyles}
`

export const HeaderLogoStyles = css`
  width: ${calculateResponsiveSize(260)};
  height: ${calculateResponsiveSize(24)};
  background: #ffffff;
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${Logo});
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  -webkit-mask-image: url(${Logo});
  transition: background 0.25s;
  cursor: pointer;
  ${mediaMax('desktopSm')} {
    width: ${calculateTabletResponsiveSize(260)};
    height: ${calculateTabletResponsiveSize(24)};
  }
  ${mediaMax('mobile')} {
    width: 195px;
    height: 18px;
    margin: 0 auto 0 0;
    .open & {
      background: #000000;
    }
  }
`

export const HeaderLogo = styled.div`
  ${HeaderLogoStyles}
`

export const List = styled.ul`
  display: flex;
  gap: ${calculateResponsiveSize(40)};
  margin: 0 0 0 auto;
  padding: 0;
  list-style: none;
  ${mediaMax('desktopSm')} {
    gap: ${calculateTabletResponsiveSize(40)};
  }
  ${mediaMax('mobile')} {
    gap: 40px;
  }
`

export const ListItem = styled.li``

export const Item = styled.div`
  cursor: pointer;
`

export const ButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${calculateResponsiveSize(85)};
  height: ${calculateResponsiveSize(25)};
  font-size: ${calculateResponsiveSize(10)};
  color: #ffffff;
  font-weight: 600;
  letter-spacing: ${calculateResponsiveSize(1)};
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #ffffff;
  transition: color 0.25s, border 0.25s, background 0.25s;
  position: relative;
  cursor: pointer;
  &:hover {
    color: #000000;
    background: #ffffff;
  }
  ${mediaMax('desktopSm')} {
    width: ${calculateTabletResponsiveSize(85)};
    height: ${calculateTabletResponsiveSize(25)};
    font-size: ${calculateTabletResponsiveSize(10)};
    letter-spacing: ${calculateTabletResponsiveSize(1)};
  }
  ${mediaMax('mobile')} {
    width: 60px;
    height: 20px;
    font-size: 9px;
    letter-spacing: 0.9px;
    &:hover {
      color: #ffffff;
      background: transparent;
    }
    .open & {
      color: #000000;
      border: 1px solid #000000;
    }
  }
`

export const ButtonInquire = styled.div`
  ${ButtonStyles}
`

export const ButtonMenu = styled.div`
  ${ButtonStyles}
`

export const Close = styled.span`
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  border-radius: 6px;
  border: 1px solid #000000;
  background: #ffffff;
  opacity: 0;
  transition: opacity 0.25s;
  z-index: 1;
  cursor: pointer;
  &:hover {
    color: #ffffff;
    background: #000000;
  }
  .active & {
    opacity: 1;
  }
  ${mediaMax('mobile')} {
    color: #ffffff;
    background: #000000;
  }
`
